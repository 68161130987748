import React from "react";
import styled from "styled-components";
import { Banner } from "./Banner";
import BecomeStartup from "./BecomeStartup";
import MoreThan from "./MoreThan";
import WhatYouCan from "./WhatYouCan";
import SillicolValley from "./SillicolValley";
import Summary from "./Sumary";
import scrollTo from "gatsby-plugin-smoothscroll";

export const AcademyContainer = ({ data }) => {
  const onScroll = (id) => {
    if (document.getElementById(id)) {
      document.getElementById(id).classList.add("style");
      scrollTo("#" + id);
    }
    // scrollTo(id);
  }
  return (
    <WrapperLayout>
      <Banner
        banner={data?.banner?.url}
        title={data?.banner_title?.text}
        description={data?.banner_description?.text}
        button_group={data?.banner_button_group}
        onScroll={onScroll}
      />
      <BecomeStartup
        author={data?.body[0]?.primary?.author?.text}
        title={data?.body[0]?.primary?.title?.raw}
        description={data?.body[0]?.primary?.description?.raw}
        dataFundamental={data?.body1 === undefined ? {} : data?.body1}
      />
      <WhatYouCan data={data?.body2[0] === undefined ? {} : data?.body2[0]} />
      <MoreThan
        data={data?.body3[0] === undefined ? {} : data?.body3[0]}
        dataMarketing={data?.body4[0] === undefined ? {} : data?.body4[0]}
      />
      {data?.body5[0] && (
        <SillicolValley
          data={data?.body5[0] === undefined ? {} : data?.body5[0]}
        />
      )}
      <Summary data={data?.body6[0] === undefined ? {} : data?.body6} />
    </WrapperLayout>
  );
};

const WrapperLayout = styled.div`
  margin-bottom: 100px;
  margin: 0 auto;
  margin-top: 90px;
  position: relative;
`;
