import { RichText } from "prismic-reactjs";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import { Link } from "../../components/Link/Link";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { Text } from "../../components/Text/Text";
import AirbnbImage from "../../images/Academy-airbnb.png";
import AppleImage from "../../images/Academy-apple.png";
import GoogleImage from "../../images/Academy-google.png";
import SlackImage from "../../images/Academy-slack.png";
import UberImage from "../../images/Academy-uber.png";
import brand1 from "../../images/Service-staup-barnd-1.png";
import brand2 from "../../images/Service-staup-barnd-2.png";
import brand3 from "../../images/Service-staup-barnd-3.png";
import { color, typography } from "../../shared/style";
import useWindowSize from "../AcademyDetailContainer/useWindowSize";
import {redirectPage} from "../../hooks/redirect"

const SillicolValley = ({ listBrand, list, data, ...props }) => {
  const [width] = useWindowSize();
  const [listShow, setListShow] = useState([]);
  const [showMore, setShowMore] = useState(true);
  const [index, setIndex] = useState(0);
  const { items, primary } = data;

  useEffect(() => {
    const newList = [...items];
    if (width < 650) {
      setListShow(newList.splice(index, 4));
      setIndex(4);
    } else {
      setListShow(newList.splice(index, 6));
      setIndex(6);
    }
    if (index >= items.length) {
      setShowMore(false);
    }
    //eslint-disable-next-line
  }, []);

  //when index change
  useEffect(() => {
    const newList = [...items];
    setListShow(newList.splice(0, index));
    if (index >= items.length) {
      setShowMore(false);
    }
  }, [index, items]);

  const handleLoardMore = () => {
    if (width < 650) {
      if (index < items.length) {
        setIndex((pre) => pre + 4);
      } else {
        setIndex((pre) => pre);
      }
    } else {
      if (index < items.length) {
        setIndex((pre) => pre + 6);
      } else {
        setIndex((pre) => pre);
      }
    }
  };
  if(!items || !primary) return null;
  const Item = ({ data, uid }) => (
    <Wraper>
      <WrapperContent>
        <WrapImage>
          <Image src={data.partner.document?.data?.logo?.url}></Image>
        </WrapImage>
        <Content>
          <WrapContentBody>
            <Title
              class="title-box"
              tag="h5"
              capital="uppercase"
              font="Alternate Gothic No1 D"
              color={color.primary.blue}
              weight={typography.weight.regular1}
              lineHeight={36}
              size={typography.size.l1}
              className="hightlight"
            >
              {data?.program_title?.text}
            </Title>

            <Description
              tag="p"
              weight={typography.weight.normal}
              color={color.netraul.black100}
              font="Oxygen"
              lineHeight={26}
            >
              {data?.program_description?.text}
            </Description>
          </WrapContentBody>
          <WrapContentFooter>
            <Btn
              onClick={() => 
                redirectPage({
                  url: data?.program_link?.url,
                  type: data?.program_link?.type,
                  link_type: data?.program_link?.link_type
                })
              }
              size={typography.size.s3}
              color={color.primary.blue}
              weight={typography.weight.bold}
              lineHeight={26}
            >
              <span>Learn more</span>
            </Btn>
            {/* <Date
              font={typography.type.primary}
              size={typography.size.s2}
              color={color.netraul.black50}
              lineHeight={22}
              weight={typography.weight.primary}
            >
              {data.body[0]?.primary?.start_date} -{" "}
              {data.body[0]?.primary.end_date}
            </Date> */}
          </WrapContentFooter>
        </Content>
      </WrapperContent>
    </Wraper>
  );

  return (
    <WrapperSection id="academy">
      <HeaderContainer>
        <TitleHeader
          font={typography.type.code}
          color={color.primary.blue}
          weight={typography.weight.regular1}
          align="center"
          size={typography.size.code}
          lineHeight={90}
          capital="uppercase"
        >
          <RichText
            render={primary.title.raw}
            htmlSerializer={htmlSerializer}
          />
        </TitleHeader>
        <DescriptionHeader
          font={typography.type.primary}
          color={color.netraul.black80}
          weight={typography.weight.normal}
          align="center"
          size={typography.size.s3}
          lineHeight={26}
        >
          <RichText
            render={primary.description.raw}
            htmlSerializer={htmlSerializer}
          />
        </DescriptionHeader>
      </HeaderContainer>
      {/* <BrandListContainer>
        {listBrand.map((item, index) => (
          <BrandImage key={index}>
            <Image src={item.image} alt={item.alt}></Image>
          </BrandImage>
        ))}
      </BrandListContainer> */}

      <WrapBody>
        {listShow?.map((data, index) => (
          <Item
            data={data}
            // uid={data.course.uid}
            key={index}
          />
        ))}
      </WrapBody>
      {items.length > 6 && (
        <ButtonContainer>
          <ButtonExtended
            onClick={handleLoardMore}
            type="primary"
            disable={!showMore}
          >
            Load More Courses
          </ButtonExtended>
        </ButtonContainer>
      )}
    </WrapperSection>
  );
};
const WrapContentBody = styled.div``;
const WrapContentFooter = styled.div``;
const WrapperSection = styled.div`
  margin: auto;
  box-sizing: border-box;
  max-width: 1500px;
  margin-top: 130px;
  padding: 120px 131px 100px;
  background-color: ${color.primary.blue10};
  @media (max-width: 1200px) {
    margin-top: 80px;
    padding: 100px 40px;
  }
  @media (max-width: 992px) {
    margin-top: 80px;
    padding: 80px 30px;
  }
  @media (max-width: 650px) {
    margin-top: 70px;
    padding: 80px 20px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TitleHeader = styled(Text)`
  display: block;
  letter-spacing: -0.015em;
  max-width: 784px;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
    font-size: 72px !important;
    line-height: 72px;
  }
  @media (max-width: 650px) {
    width: auto;
    font-size: 62px !important;
    line-height: 62px;
  }
`;
const DescriptionHeader = styled(Text)`
  margin-top: 32px;
  display: block;
  width: 640px;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
    width: auto;
  }
  @media (max-width: 650px) {
    margin-top: 30px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const WrapBody = styled.div`
  display: grid;
  gap: 34px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-top: 62px;
  @media (max-width: 1250px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 20px;
  }
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: 650px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin-top: 40px;
  }
`;

const Wraper = styled.div`
  box-sizing: border-box;
  background-color: ${color.primary.white};
`;

const WrapperContent = styled.div`
  background-color: ${color.primary.white};
  padding: 29px 30px 28px 30px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  &:hover {
    filter: drop-shadow(0px 18px 25px rgba(0, 0, 0, 0.12));
    .hightlight {
      color: #3dae73;
    }
  }
  @media (max-width: 650px) {
    padding: 20px;
    height: auto;
  }
`;

const WrapImage = styled.div`
  width: 110px;
  box-sizing: border-box;
  margin-bottom: 23px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;
const Title = styled(Text)`
  letter-spacing: -0.015em;
  color: ${(props) => props.color};
`;
const Description = styled(Text)`
  margin-top: 20px;
  font-weight: 400;
`;

const Btn = styled(Link)`
  display: block;
  font-weight: bold;
  margin-top: 20px;
  span {
    display: inline-block;
    text-decoration: underline ${color.primary.blue};
    text-underline-offset: 5px;
  }
`;

// const Date = styled(Text)`
//   display: block;
//   margin-top: 23px;
// `;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 60px;
`;

const ButtonExtended = styled(Button)`
  border-radius: 40px;
  height: 65px;
  white-space: nowrap;
  text-transform: capitalize;
  padding: 20px 45px;
`;

SillicolValley.propTypes = {
  listBrand: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      alt: PropTypes.string,
    })
  ),
  list: PropTypes.arrayOf(
    PropTypes.shape({
      brand: PropTypes.string,
      title: PropTypes.string,
      color: PropTypes.string,
      description: PropTypes.string,
      btn: PropTypes.string,
      dateTemp: PropTypes.string,
    })
  ),
};

SillicolValley.defaultProps = {
  listBrand: [
    {
      image: AppleImage,
      alt: "image",
    },
    {
      image: GoogleImage,
      alt: "image",
    },
    {
      image: AirbnbImage,
      alt: "image",
    },
    {
      image: SlackImage,
      alt: "image",
    },
    {
      image: UberImage,
      alt: "image",
    },
  ],
  list: [
    {
      brand: brand1,
      title: "Digital Product Management: Modern Fundamentals",
      color: color.primary.blue,
      description:
        "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
      btn: "Learn more",
      date: "August 23 - September 6, 2021",
    },
    {
      brand: brand2,
      title: "Leadership: How to define your vision.",
      color: color.primary.blue,
      description:
        "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
      btn: "Learn more",
      date: "August 23 - September 6, 2021",
    },
    {
      brand: brand3,
      title: "Digital Product Management: Modern Fundamentals",
      color: color.primary.blue,
      description:
        "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
      btn: "Learn more",
      date: "August 23 - September 6, 2021",
    },
    {
      brand: brand1,
      title: "Digital Product Management: Modern Fundamentals",
      color: color.primary.blue,
      description:
        "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
      btn: "Learn more",
      date: "August 23 - September 6, 2021",
    },
    {
      brand: brand2,
      title: "Digital Product Management: Modern Fundamentals",
      color: color.primary.blue,
      description:
        "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
      btn: "Learn more",
      date: "August 23 - September 6, 2021",
    },
    {
      brand: brand3,
      title: "Leadership: How to define your vision.",
      color: color.primary.blue,
      description:
        "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
      btn: "Learn more",
      date: "August 23 - September 6, 2021",
    },
  ],
};

export default SillicolValley;
