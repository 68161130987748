import { useLayoutEffect, useState } from "react";

function useWindowSize() {
  const [size, setSize] = useState(typeof window !=="undefined" ? [window.innerWidth, window.innerHeight] : [0,0]);
  useLayoutEffect(() => {
    function updateSize() {
      typeof window !== "undefined" &&
        setSize([window.innerWidth, window.innerHeight]);
    }
    typeof window !== "undefined" &&
      window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export default useWindowSize;
