import React from "react";
import styled from "styled-components";
import { Button } from "../../../components/Button/Button";
import { CarouselSlick } from "../../../components/CarouselSlick/CarouselSlick";
import left from "../../../images/arrow-left.png";
import right from "../../../images/arrow-right.png";
import { color } from "../../../shared/style";
import { ItemAuthor } from "./ItemAuthor";

export const ItemAuthorList = ({ items, noLink }) => {
  const NextArrowButton = <ButtonSlide icon={right}></ButtonSlide>;
  const PrevArrowButton = <ButtonSlide icon={left}></ButtonSlide>;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: NextArrowButton,
    prevArrow: PrevArrowButton,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          nextArrow: NextArrowButton,
          prevArrow: PrevArrowButton,
          appendDots: (dots) => (
            <div
              style={{
                position: "initial",
                marginTop: "20px",
              }}
            >
              <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
          ),
        },
      },
      {
        breakpoint: 756,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          nextArrow: null,
          prevArrow: null,
          appendDots: (dots) => (
            <div
              style={{
                position: "initial",
                marginTop: "20px",
              }}
            >
              <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
          ),
        },
      },
    ],
  };

  const showList = (list) =>
    list?.map((item, index) => (
      <ItemAuthor item={item} key={index} noLink={noLink} />
    ));

  return (
    <WrapperGeneral>
      <CarouselSlick settings={{ ...settings }}>
        {showList(items)}
      </CarouselSlick>
    </WrapperGeneral>
  );
};

const ButtonSlide = styled(Button)`
  background: rgba(23, 41, 64, 0.8);
  width: 60px;
  height: 60px;
  border-radius: 0;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: ${color.primary.yellow};
    border: none;
  }
  span {
    display: none;
  }
  &:before {
    content: "";
  }
`;

const WrapperGeneral = styled.div`
  box-sizing: border-box;
  margin: 0 -17px;
  margin-top: 60px;
  position: relative;
  @media (max-width: 1330px) and (min-width: 1201px) {
    margin-right: -10px;
    margin-left: -10px;
  }
  @media (max-width: 650px) {
    margin: 0;
    margin-top: 40px;
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
    & > div {
      height: 100%;
    }
  }

  .slick-dots li button:before {
    font-size: 16px;
    color: ${color.primary.blue};
  }
  .slick-dots li.slick-active button:before {
    opacity: 1;
  }

  .slick-arrow {
    position: absolute;
    top: -130px;
    @media (max-width: 1200px) {
      display: none !important;
    }
  }
  .slick-next {
    right: 17px;
    left: auto;
  }
  .slick-prev {
    right: 87px;
    left: auto;
  }
`;
