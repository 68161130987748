import { RichText } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import { Link } from "../../../components/Link/Link";
import { Text } from "../../../components/Text/Text";
import htmlSerializer from "../../../components/RichtextHelper/CustomText";
import pin from "../../../images/pin.svg";
import { color, typography } from "../../../shared/style";
import { navigate } from "gatsby";

export const ItemAuthor = ({ item, noLink }) => {
  const redirectPage = (url) => {
    navigate(`${url}`);
  };

  return (
    // <BoxInfo>
    //   <WrapInfoAuthorBody>
    //     <WrapInfoAuthor>
    //       <Image src={item?.avatar?.url} />
    //       <RightInfo>
    //         <Icon src={pin} />
    //         <Name>{item?.name?.text}</Name>
    //         <Position>{item?.posittion?.text || item?.position?.text}</Position>
    //       </RightInfo>
    //     </WrapInfoAuthor>
    //     <Description>
    //       <RichText
    //         render={item?.comment?.raw}
    //         htmlSerializer={htmlSerializer}
    //       />
    //     </Description>
    //   </WrapInfoAuthorBody>

    //   {!noLink ? (
    //     <WrapButton>
    //       <BtnLearnMore
    //         onClick={() => redirectPage("#")}
    //         size={16}
    //         lineHeight={26}
    //       >
    //         Learn more
    //       </BtnLearnMore>
    //     </WrapButton>
    //   ) : null}
    // </BoxInfo>
    <WrapperItem>
      <Item>
        <Info>
          <Avatar src={item?.avatar.url} />
          <Detail>
            <Icon src={pin} />
            <Name
              font="Oxygen"
              size={20}
              lineHeight={28}
              weight={typography.weight.bold}
              color={color.netraul.black100}
            >
              {item.name.text}
            </Name>
            <Position
              font="Oxygen"
              weight={typography.weight.regular}
              size={14}
              lineHeight={22}
              color={color.netraul.black100}
            >
              {item?.position?.text || item?.posittion?.text}
            </Position>
          </Detail>
        </Info>
        <Comment
          font="Oxygen"
          lineHeight={26}
          color={color.netraul.black90}
        >
          <RichText
            render={item?.comment?.raw}
            htmlSerializer={htmlSerializer}
          />
        </Comment>
      </Item>
      {!noLink ? (
        <WrapButton>
          <BtnLearnMore
            onClick={() => redirectPage("#")}
            size={16}
            lineHeight={26}
          >
            Learn more
          </BtnLearnMore>
        </WrapButton>
      ) : null}
    </WrapperItem>
  );
};

const WrapperItem = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: auto !important;
  display: block;
  margin: 0 17px;
  padding-bottom: 40px;
  box-sizing: border-box;
  background: #fff;
  background: linear-gradient(to bottom, #f4f6f8 43px, #fff 0px);

  @media (max-width: 650px) {
    margin: 0;
  }
`;
const Item = styled.div`
  padding: 0 30px;
  box-sizing: border-box;
  @media (max-width: 650px) {
    padding: 0 20px;
  }
`;

const Icon = styled.img`
  max-width: 22px;
  max-height: 22px;
  width: 100%;
  height: 100%;
  padding: 33px 0 30px 0;
`;
const Info = styled.div`
  display: flex;
  margin-bottom: 40px;
`;
const Avatar = styled.img`
  max-height: 136px;
  max-width: 102px;
  object-fit: cover;
`;
const Detail = styled.div`
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1190px) and (min-width: 1100px) {
    padding-left: 20px;
  }
  @media (max-width: 1100px) and (min-width: 1000px) {
    padding-left: 10px;
  }
`;
const Name = styled(Text)``;
const Position = styled(Text)`
  opacity: 0.67;
`;
const Comment = styled(Text)``;

const WrapButton = styled.div`
  padding: 0 30px;
  margin-top: 20px;
  @media (max-width: 650px) {
    padding: 0 20px;
  }
`;
const BtnLearnMore = styled(Link)`
  font-weight: ${typography.weight.bold};
  color: ${color.primary.blue};
  border-bottom: 1px solid ${color.primary.blue};
`;