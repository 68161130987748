import React from "react";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";
import { redirectPage } from '../../hooks/redirect'

const Summary = ({data}) => {
  const { primary } = data[0] || {};
  if (!primary) return null;
  return (
    <WrapperSection>
      <Header
        tag="h4"
        weight={typography.weight.regular1}
        color={color.primary.white}
        font={typography.type.code}
        size={typography.size.l3}
        capital="uppercase"
        lineHeight={56}
        align="center"
      >
        {primary?.title?.text}
      </Header>
      <WrapperButton>
        {data?.length && data.map(({primary: item}) => (
          <Btn
            onClick={() =>
              redirectPage({ url: item?.button_link?.url, type: item?.button_link?.type, link_type: item?.button_link?.link_type })
            }
            type="tertiary"
          >
            {item?.button_label?.text}
          </Btn>
        ))}
      </WrapperButton>
    </WrapperSection>
  );
};

const WrapperSection = styled.div`
  margin: auto;
  box-sizing: border-box;
  max-width: 1500px;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${color.primary.blue};
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
  }
`;

const Header = styled(Text)`
  width: 796px;
  letter-spacing: -0.015em;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
    width: 600px;
  }
  @media (max-width: 650px) {
    width: 335px;
    font-size: 36px !important;
    line-height: 36px;
  }
`;
const WrapperButton = styled.div`
  display: flex;
  gap: 10px;
`;
const Btn = styled(Button)`
  border-radius: 40px;
  margin-top: 88px;
  padding: 20px 45px;
`;

export default Summary;
