import { RichText } from "prismic-reactjs";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import htmlSerializer from "../../components/RichtextHelper/CustomText";
import { Text } from "../../components/Text/Text";
import { redirectPage } from "../../hooks/redirect";
import Forbes from "../../images/Academy-forbes.png";
import { color, typography } from "../../shared/style";
import { ItemAuthorList } from "../DenContainer/AuthorContainer/ItemAuthorList";

const MoreThan = ({ list, data, dataMarketing, ...props }) => {
  const { items, primary } = data || {};
  // const redirectPage = (url) => {
  //   navigate(`/${url}`);
  // };
  if(!primary || !items) return null;
  return (
    <WrapperSection>
      <HeaderContainer>
        <TitleHeader
          font={typography.type.secondary}
          color={color.primary.blue}
          weight={typography.weight.regular1}
          align="center"
          size={typography.size.l3}
          lineHeight={56}
          capital="uppercase"
        >
          <RichText
            render={primary?.title?.raw}
            htmlSerializer={htmlSerializer}
          />
        </TitleHeader>
      </HeaderContainer>

      <WrapListAuthor className="academy-author">
        <ItemAuthorList items={items} noLink={true} />
      </WrapListAuthor>
      <FooterContainer>
        <TitleFooter
          font={typography.type.code}
          color={color.primary.white}
          weight={typography.weight.regular1}
          align="center"
          size={typography.size.l3}
          lineHeight={56}
          capital="uppercase"
        >
          {dataMarketing?.primary?.title?.text}
        </TitleFooter>
        <ButtonFooter
          type="tertiary"
          onClick={() =>
            redirectPage({
              url: dataMarketing.primary?.button_link?.url,
              type: dataMarketing.primary?.button_link?.type,
              link_type: dataMarketing.primary?.button_link?.link_type,
            })
          }
        >
          {dataMarketing?.primary?.button_label?.text}
        </ButtonFooter>
      </FooterContainer>
    </WrapperSection>
  );
};

const WrapListAuthor = styled.div`
  margin-top: 90px;
  @media (max-width: 756px) {
    margin-top: 40px;
  }
`;
const WrapperSection = styled.div`
  margin: auto;
  box-sizing: border-box;
  max-width: 1500px;
  margin-top: 140px;
  padding: 0 131px;
  @media (max-width: 1200px) {
    margin-top: 80px;
    padding: 0 40px;
  }
  @media (max-width: 992px) {
    margin-top: 80px;
    padding: 0 30px;
  }
  @media (max-width: 650px) {
    margin-top: 100px;
    padding: 0 20px;
  }
  .academy-author {
    .slick-slide > div > div {
      background: linear-gradient(to bottom, #fff 43px, #f4f6f8 0);
    }
    .slick-arrow {
      top: -50px;
    }
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TitleHeader = styled(Text)`
  display: block;
  max-width: 694px;
  letter-spacing: -0.015em;
  @media (max-width: 1200px) {
    width: auto;
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    span {
      line-height: 62px;
      font-size: 62px !important;
    }
    text-align: left;
  }
`;

const FooterContainer = styled.div`
  background-color: ${color.primary.blue};
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
`;
const TitleFooter = styled(Text)`
  padding: 0 30px;
`;
const ButtonFooter = styled(Button)`
  margin-top: 32px;
  border-radius: 40px;
  padding: 20px 45px;
`;

MoreThan.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      step: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

MoreThan.defaultProps = {
  list: [
    {
      image: Forbes,
    },
    {
      image: Forbes,
    },
    { image: Forbes },
    {
      image: Forbes,
    },
    {
      image: Forbes,
    },
    {
      image: Forbes,
    },
    {
      image: Forbes,
    },
    {
      image: Forbes,
    },
  ],
};

export default MoreThan;
