import { useStaticQuery, graphql } from "gatsby"

export const useQueryAcademy = () => {
  const { allPrismicAcademy } = useStaticQuery(
    graphql`
      query QueryAcademy {
        allPrismicAcademy {
          nodes {
            data {
              banner_title {
                html
                text
                raw
              }
              banner_description {
                html
                text
                raw
              }
              banner {
                url
              }
              banner_button_group {
                button_label {
                  text
                }
                button_link {
                  link_type
                  url
                  type
                }
              }
              body {
                ... on PrismicAcademyBodyHighlight {
                  primary {
                    author {
                      html
                      text
                      raw
                    }
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                  }
                }
              }
              body1 {
                ... on PrismicAcademyBody1FundamentalsOfEntrepreneurship {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    image {
                      url
                    }
                    content {
                      html
                      text
                      raw
                    }
                    start_date(formatString: "DD MMM YYYY")
                    link_label {
                      text
                    }
                    link {
                      url
                      link_type
                      type
                    }
                  }
                }
              }
              body2 {
                ... on PrismicAcademyBody2HowItWorks {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    icon {
                      url
                    }
                    description {
                      html
                      text
                      raw
                    }
                  }
                }
              }
              body3 {
                ... on PrismicAcademyBody3Reviews {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    avatar {
                      url
                    }
                    name {
                      text
                    }
                    position {
                      text
                    }
                    comment {
                      html
                      text
                      raw
                    }
                  }
                }
              }
              body4 {
                ... on PrismicAcademyBody4Marketing {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    button_label {
                      text
                    }
                    button_link {
                      url
                      link_type
                      type
                    }
                  }
                }
              }
              body5 {
                ... on PrismicAcademyBody5CoursesOfPartners {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                  }
                  items {
                    partner {
                      document {
                        ... on PrismicPartners {
                          data {
                            logo {
                              url
                              alt
                            }
                          }
                        }
                      }
                    }
                    program_description {
                      html
                      text
                      raw
                    }
                    program_link {
                      url
                      type
                      link_type
                    }
                    program_title {
                      html
                      text
                      raw
                    }
                  }
                }
              }
              body6 {
                ... on PrismicAcademyBody6JoinOur {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    button_label {
                      text
                    }
                    button_link {
                      type
                      url
                      link_type
                    }
                  }
                }
              }
              body7 {
                ... on PrismicAcademyBody7GeneralCard {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    robot {
                      html
                      text
                      raw
                    }
                    image {
                      url
                      alt
                    }
                    googlebot {
                      html
                      text
                      raw
                    }
                    favicon {
                      alt
                      url
                    }
                    description {
                      html
                      text
                      raw
                    }
                    bingbot {
                      html
                      text
                      raw
                    }
                  }
                  slice_type
                }
                ... on PrismicAcademyBody7OpenGraph {
                  primary {
                    og_url {
                      url
                      type
                      link_type
                    }
                    og_type {
                      html
                      text
                      raw
                    }
                    og_title {
                      html
                      text
                      raw
                    }
                    og_site_name {
                      html
                      text
                      raw
                    }
                    og_locale {
                      html
                      text
                      raw
                    }
                    og_image_width {
                      html
                      text
                      raw
                    }
                    og_image_height {
                      html
                      text
                      raw
                    }
                    og_image {
                      alt
                      url
                    }
                    og_description {
                      html
                      text
                      raw
                    }
                  }
                  slice_type
                }
                ... on PrismicAcademyBody7TwitterCard {
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    image {
                      alt
                      url
                    }
                    description {
                      html
                      text
                      raw
                    }
                  }
                  slice_type
                }
                ... on PrismicAcademyBody7PinterestProductPin {
                  slice_type
                  primary {
                    title {
                      html
                      text
                      raw
                    }
                    description {
                      html
                      text
                      raw
                    }
                    price
                    currency
                    availability
                  }
                }
              }
            }
          }
        }
      }
      `
  )

  return {
    allPrismicAcademy
  }
}