import React from "react";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import { Text } from "../../components/Text/Text";
import banner_default from "../../images/Academy-banner.png";
import { color, typography } from "../../shared/style";
import { redirectPage } from "../../hooks/redirect";

export const Banner = ({ banner, title, description, button_group, onScroll }) => {
  // const redirectPage = (url) => {
  //   navigate(`https://fac.com`);
  // };

  return (
    <WrapperSection banner={banner}>
      <Content>
        <TextExtend
          font={typography.type.code}
          tag="h2"
          color={color.primary.white}
          capital="uppercase"
          weight={typography.weight.regular1}
          align="center"
          size={120}
          lineHeight={120}
        >
          {title}
        </TextExtend>
        <Description
          font={typography.type.primary}
          color={color.primary.white}
          weight={typography.weight.bold}
          align="center"
          size={typography.size.s3}
          lineHeight={26}
        >
          {description}
        </Description>
        <GroupButton>
          {button_group &&
            button_group?.map((button, i) => (
              <ButtonExtended
                key={i}
                onClick={() =>{
                  if(i==1 || button?.button_label?.text === 'Partner training offers') {
                    onScroll('academy')
                  } else{
                    redirectPage({ url: button?.button_link?.url, type: button?.button_link?.type, link_type: button?.button_link?.link_type })
                  }
                }
                }
                type="primary"
              >
                {button.button_label.text}
              </ButtonExtended>
            ))}
        </GroupButton>
      </Content>
    </WrapperSection>
  );
};

const GroupButton = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  @media (max-width: 650px) {
    padding: 0 50px;
    margin-bottom: 70px;
  }
`;
const ButtonExtended = styled(Button)`
  border-radius: 40px;
  height: 65px;
  white-space: nowrap;
  margin-top: 30px;
  padding: 20px 45px;
  &:last-child {
    margin-left: 18px;
    background-color: ${color.secondary.danube};
    &:hover {
      background-color: ${color.primary.white};
      color: ${color.secondary.danube};
    }
  }
  @media (max-width: 768px) {
    &:last-child {
      margin-left: 0px;
    }
  }

  @media (max-width: 650px) {
    margin-top: 40px;
    padding: 20px 53px;

    &:last-child {
      padding: 20px 30px;

      margin-top: 24px;
      margin-left: 0px;
    }
  }
`;
const TextExtend = styled(Text)`
  letter-spacing: -0.015em;
  @media (max-width: 1200px) {
    padding: 20px 0;
    font-size: 74px !important;
    line-height: 74px;
  }
  @media (max-width: 650px) {
    padding: 0;
    margin-top: 102px;
    font-size: 62px !important;
    line-height: 62px;
  }
`;
const WrapperSection = styled.div`
  min-height: 610px;
  box-sizing: border-box;
  display: flex;
  background-image: url(${(props) => props.banner ? props?.banner : banner_default});
  background-size: cover;
  background-position: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 650px) {
    min-height: 646px;
  }
`;
const Content = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  width: 80%;
  box-sizing: border-box;
  @media (max-width: 1300px) {
    width: 80%;
  }
  @media (max-width: 650px) {
    width: 100%;
    padding: 0 20px;
  }
`;

const Description = styled(Text)`
  width: 60%;
  margin-top: 16px;
  @media (max-width: 1000px) and (min-width: 651px) {
    width: 80%;
  }
  @media (max-width: 650px) {
    width: 95%;
    margin-top: 16px;
  }
`;
